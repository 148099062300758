import React from 'react';
import Image from 'next/image';
//import Link from 'next/link';
//import { AuctionSection } from '@/sections/marketplace/AuctionSection';
import Badge_1 from '@/shared/components/Badge_1';
import Badge_2 from '@/shared/components/Badge_2';
import CountdownToDate from './CountdownToDate';
import Link from 'next/link';

const Hero = () => {
    const authorImage =
        'https://thumbs.dreamstime.com/b/default-profile-picture-avatar-photo-placeholder-vector-illustration-default-profile-picture-avatar-photo-placeholder-vector-189495158.jpg';

    //const totalItems = nftsDataWithAhInfo.length;

    //const Collections;
    //const Artists;
    //const targetDate_1 = '2025-01-31T23:59:59';
    const targetDate_2 = '2025-05-09T23:59:59'; // scoate secundele

    return (
        <>
            <section className="hero relative py-20 md:pt-32">
                {/*<picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden"> // did not notice its use
                    <Image
                        width={1519}
                        height={773}
                        priority
                        src="/images/gradient.jpg"
                        alt="gradient"
                        className="h-full w-full object-cover"
                    />
                </picture> - Dragos Bacitea:this image does nothing and can be deleted or we can keep a placeholder for other projects*/}

                {/* <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
                    <Image
                        width={1519}
                        height={773}
                        priority
                        className="h-full w-full"
                        src="/images/gradient_dark.jpg"
                        alt="gradient dark"
                    />
                </picture> */}

                <div className="grid h-full items-center gap-0 lg:grid-cols-12 ">
                    {/** tittle / info */}
                    <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-10 xl:col-span-5 xl:pl-[20%] xl:pr-[10%]">
                        <div className="mb-10 w-full sm:flex sm:space-x-4">
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <span className="block font-display text-3xl text-[#8DD059]"> 104</span>
                                <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                                    ArtWorks
                                </span>
                            </div>
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <span className="block font-display text-3xl text-[#737EF2]"> 60</span>
                                <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                                    Collections
                                </span>
                            </div>
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <span className="block font-display text-3xl text-[#F35BC7]"> 200</span>
                                <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                                    Artists
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white md:text-left lg:text-5xl xl:text-6xl">
                                <h2>
                                    <span>Ready to begin your</span>
                                    <span className="animate-gradient"> NFT </span>
                                    journey ?
                                </h2>
                            </div>
                        </div>

                        <div className="mb-8 text-center text-lg dark:text-jacarta-200 md:text-left">
                            {' '}
                            The leading platform for artists and collectors of both digital and physical artworks,
                            delivering worldwide access and shipping{' '}
                        </div>

                        <div className="flex space-x-4 text-center">
                            <div className="text-center my-1 mb-12">
                                <a
                                    href="https://icoholder.com/en/a-nft-world-1071376"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-36 rounded-full animate-gradient py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                                >
                                    ICO Listing
                                </a>
                            </div>
                            <div className="text-center my-1 mb-12">
                                <a
                                    href="https://artechfusion.gallery/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-36 rounded-full animate-gradient py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                                >
                                    3D Gallery
                                </a>
                            </div>
                        </div>
                        {/* counters */}
                         <div className="mb-10 w-full sm:flex sm:space-x-4">
                            {/*<div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <div className="block font-display text-base text-[#8DD059]">
                                    <h1 className="animate-gradient text-xl">Art Tech Fusion</h1>
                                    <h1 className="animate-gradient mb-3 text-xl">Open Call</h1>
                                    <h1 className="animate-gradient mb-3 text-xl">Round 2</h1>
                                    <div className="mb-5 text-xl">
                                        <CountdownToDate targetDate={targetDate_1} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                                <div className="block font-display text-base text-[#8DD059]">
                                    <h1 className="animate-gradient mb-3 text-xl">Art Tech Fusion 2025 Proposed START</h1>
                                    <div className="text-xl">
                                        <CountdownToDate targetDate={targetDate_2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** nft preview */}
                    <div className="relative xl:col-start-8 hidden sm:block">
                        {
                            // <Image
                            //     width={150}
                            //     height={150}
                            //     src="/images/Badge.png"
                            //     className="absolute top-0 z-10 -ml-16  md:top-[12%]"
                            //     alt="badge"
                            // />
                        }
                        <div className="md:flex cube">
                            {/** first article */}
                            <div className="box box1">
                                <article>
                                    <div>
                                        <figure className="relative">
                                            <>
                                                <Image
                                                    width={300}
                                                    height={300}
                                                    src={
                                                        'https://gateway.irys.xyz/3mr9QpAPNWr7gJJ9dbmOU7Y4HWDCnqQTmlnwrDAMXzM'
                                                    }
                                                    alt="item 1"
                                                    className="w-[400px] h-[400px]"
                                                />

                                                <div>
                                                    <Badge_1 />
                                                </div>
                                            </>
                                        </figure>
                                        {/* <div className="p-6">
                                            <div className="">
                                                <Image
                                                    width={40}
                                                    height={40}
                                                    src={authorImage}
                                                    alt="avatar"
                                                    className="mr-4 h-10 w-10 rounded-full"
                                                />
                                                <div>
                                                    <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                                        Rețele Verzi - part 1
                                                    </span>

                                                    <div className="flex justify-between pt-6 pb-2">
                                                        <p className="text-2xs text-accent">Anghel</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </article>
                            </div>

                            {/*secound article */}
                            <div className="box box2">
                                <article>
                                    <div>
                                        <figure className="relative">
                                            <>
                                                <Image
                                                    width={300}
                                                    height={300}
                                                    src={
                                                        'https://gateway.irys.xyz/8eTT9oMUEb8tKZJ22av42XI6goorztaV9iIOie8WXD4'
                                                    }
                                                    alt="item 1"
                                                    className="w-[400px] h-[400px]"
                                                />
                                                <div>
                                                    <Badge_1 />
                                                </div>
                                            </>
                                        </figure>
                                        {/* <div className="p-6">
                                                <div className="">
                                                    <Image
                                                        width={40}
                                                        height={40}
                                                        src={authorImage}
                                                        alt="avatar"
                                                        className="mr-4 h-10 w-10 rounded-full"
                                                    />
                                                    <div>
                                                        <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                                            Rețele Verzi - part 2
                                                        </span>
                                                        <div className="flex justify-between pt-6 pb-2">
                                                            <p className="text-2xs text-accent">Anghel</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>
                                </article>
                            </div>

                            {/* 3rd Article */}
                            <div className="box box3">
                                <article>
                                    <div>
                                        <figure className="relative">
                                            <>
                                                <Image
                                                    width={300}
                                                    height={300}
                                                    src={
                                                        'https://gateway.irys.xyz/7QYiypDL8rdo89vUGvyMZbUsAXBoVIWlAcUb-WHsBqM'
                                                    }
                                                    alt="item 1"
                                                    className="w-[400px] h-[400px]"
                                                />
                                                <div>
                                                    <Badge_1 />
                                                </div>
                                            </>
                                        </figure>
                                        {/* <div className="p-6">
                                                    <div className="">
                                                        <Image
                                                            width={40}
                                                            height={40}
                                                            src={authorImage}
                                                            alt="avatar"
                                                            className="mr-4 h-10 w-10 rounded-full"
                                                        />

                                                        <div>
                                                            <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                                                Art Tech Fusion
                                                            </span>
                                                            <div className="flex justify-between pt-6 pb-2">
                                                                <p className="text-2xs text-accent">A-NFT-Admin</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                    </div>
                                </article>
                            </div>

                            {/* 4rd Article */}
                            <div className="box box4">
                                <article>
                                    <div>
                                        <figure className="relative">
                                            <>
                                                <Image
                                                    width={300}
                                                    height={300}
                                                    src={
                                                        'https://gateway.irys.xyz/sYswj0CP2r9djQkB0cEPsJYQQaD4zjqaDxZCZ23xddM'
                                                    }
                                                    alt="item 1"
                                                    className="w-[400px] h-[400px]"
                                                />
                                                <div>
                                                    <Badge_1 />
                                                </div>
                                            </>
                                        </figure>
                                    </div>
                                </article>
                            </div>

                            {/* 5rd Article */}
                            <div className="box box5">
                                <article>
                                    <div>
                                        <figure className="relative">
                                            <>
                                                <Image
                                                    width={300}
                                                    height={300}
                                                    src={
                                                        'https://gateway.irys.xyz/vfoaO26BxZr36I2CV2M1oOit28RLZl7zX7cwYaQwq8A'
                                                    }
                                                    alt="item 1"
                                                    className="w-[400px] h-[400px]"
                                                />
                                                <div>
                                                    <Badge_1 />
                                                </div>
                                            </>
                                        </figure>
                                    </div>
                                </article>
                            </div>

                            {/* 6rd Article */}
                            <div className="box box6">
                                <article>
                                    <div>
                                        <figure className="relative">
                                            <>
                                                <Image
                                                    width={300}
                                                    height={300}
                                                    src={
                                                        'https://gateway.irys.xyz/3-klPgSbIZiHDDLjfB3H4TzEMstPumLRpgLn_rDJFO4'
                                                    }
                                                    alt="item 1"
                                                    className="w-[400px] h-[400px]"
                                                />
                                                <div>
                                                    <Badge_2 />
                                                </div>
                                            </>
                                        </figure>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
